import React, { useState, useEffect, useCallback } from 'react';
import { Space, Select, Upload, Button, Modal, Input, Card, Form, InputNumber, Tag, Avatar, Spin } from 'antd';
import { sendPersonaUpdate, getPersonaDetails, getInferenceOptions } from '../messaging';
import { Checkbox } from '../../node_modules/antd/es/index';
import ADGroupsDropdown from './PickADGroup';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { InteractionType } from "@azure/msal-browser";
import { Client } from "@microsoft/microsoft-graph-client";
import { UploadOutlined } from '@ant-design/icons';
const { Meta } = Card;
const { Option } = Select;

const { TextArea } = Input;

const PersonaConfigurator = ({ msalInstance, personaname, tokenManager, disabled, onSaved, onCanceled, mode, visible }) => {
    const [groupOptions, setGroupOptions] = useState([]);
    const [persona, setPersona] = useState(null);
    /*const [agents, setAgents] = useState(null);*/
    const [loading, setLoading] = useState(false);
    //const [hasSystemPrompt, setHasSystemPrompt] = useState(false);
    const [busyMessage, setBusyMessage] = useState("Loading...");
    const [inferenceOptions, setInferenceOptions] = useState(null);
    const inputRefs = React.useRef({});



    const loadPersonaForEditing = useCallback(async (tokenManager, personaname, setPersona, setInferenceOptions, setLoading) => {
        const aadToken = await tokenManager.getAADToken();
        getPersonaDetails(aadToken, personaname).then((data) => {           

            if (mode === 'add') {
                data.Id = null;
            }

            setPersona(data);
        }).then(() => {
            getInferenceOptions(aadToken).then((data) => {                
                //transform the string array into options for the ant design select list
                var options = data.map((item) => ({ value: item.model, label: item.model, data: item }));
                setInferenceOptions(options);
            }).then(() => {
                setBusyMessage(null);
            });
        });
    }, [mode]);



    async function handleSave() {
        setBusyMessage("Saving...");

        const aadToken = await tokenManager.getAADToken();

        //for (const prop in persona) {
        //    if (persona[prop] === null) {
        //        persona[prop] = ' ';
        //    } else if (Array.isArray(persona[prop])) {
        //        persona[prop] = persona[prop].map((item) => (item === null ? ' ' : item));
        //    }
        //}

        sendPersonaUpdate(aadToken, persona);
        setPersona(null);
        /*setAgents(null);*/

        if (onSaved)
            await onSaved();


        setBusyMessage(null);
    };

    async function handleCancel() {
        setPersona(null);
        /*setAgents(null);*/
        setBusyMessage(null);
        if (onCanceled)
            await onCanceled();
    }

    const getADGroups = useCallback(async () => {
        async function getGraphClient() {
            const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(msalInstance, {
                account: msalInstance.getAllAccounts()[0],
                interactionType: InteractionType.Popup,
                scopes: ['User.Read', "Group.Read.All"],
            });

            const graphClient = Client.initWithMiddleware({ authProvider: authProvider });
            return graphClient;
        }


        if (getGraphClient) {
            const client = await getGraphClient();

            const result = await client.api('/groups')
                .select('displayName,id')
                .filter("startsWith(displayName,'SG-')")
                .get();

            const updatedGroupsOptions = result.value.map((group) => ({
                key: group.id,
                text: group.displayName
            }));
            setGroupOptions(updatedGroupsOptions);
        }
    }, [msalInstance]);

    //function handleKeyDown(event) {
    //    const textarea = event.target;
    //    const start = textarea.selectionStart;
    //    const end = textarea.selectionEnd;
    //    if (event.key === "Enter") {
    //        event.preventDefault();
    //        textarea.value =
    //            textarea.value.substring(0, start) +
    //            "\n" +
    //            textarea.value.substring(end);
    //        textarea.selectionStart = textarea.selectionEnd = start + 1;
    //        return false;
    //    }
    //}

    useEffect(() => {
        if (visible && !loading && persona == null) {
            loadPersonaForEditing(tokenManager, personaname, setPersona, setInferenceOptions, setLoading).then(() => {
                getADGroups();
                setLoading(false);
            });
        }
    }, [getADGroups, loadPersonaForEditing, loading, persona, personaname, tokenManager, visible])


    useEffect(() => {
        if (visible && !loading && persona == null) {
            setLoading(true);
        }
    }, [getADGroups, loading, personaname, tokenManager, visible, persona]);

    //useEffect(() => {
    //    if (persona !== null && persona !== undefined && persona.Agents !== undefined && persona.Agents !== null) {
    //        var agentsList = Object.entries(persona.Agents);
    //        var agentList2 = agentsList.sort((a, b) => parseInt(a[1].DisplayOrder) > parseInt(b[1].DisplayOrder) ? 1 : -1);
    //        setAgents(agentList2);
    //    }
    //}, [persona]);

    const handleNestedObjectChange = (prevState, nameParts, value) => {
        const [firstKey, secondKey, thirdKey] = nameParts;

        const oldFirstLevelValue = prevState[firstKey] || {};
        const oldSecondLevelValue = oldFirstLevelValue[secondKey] || {};

        const newSecondLevelValue = { ...oldSecondLevelValue, [thirdKey]: value };
        const newFirstLevelValue = { ...oldFirstLevelValue, [secondKey]: newSecondLevelValue };

        return { ...prevState, [firstKey]: newFirstLevelValue };
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        const nameParts = name.split('.');


        // Save current cursor position  
        const cursorPosition = e.target.selectionStart;

        if (nameParts.length === 3) {
            setPersona(prevState => handleNestedObjectChange(prevState, nameParts, value));
        }
        else {
            setPersona(prevState => ({
                ...prevState,
                [nameParts[0]]: value
            }));
        }

        if (inputRefs.current[name]) {
            inputRefs.current[name].selectionStart = cursorPosition;
            inputRefs.current[name].selectionEnd = cursorPosition;
        }
    };

    const handleNumberChange = (value, name) => {
        const nameParts = name.split('.');

        if (nameParts.length === 3) {
            setPersona(prevState => handleNestedObjectChange(prevState, nameParts, value));
        }
        else {
            setPersona(prevState => ({
                ...prevState,
                [nameParts[0]]: value
            }));
        }
    }

    const handleSelectChange = (value, name) => {
        const nameParts = name.split('.');

        if (nameParts.length === 3) {
            setPersona(prevState => handleNestedObjectChange(prevState, nameParts, value));
        }
        else {
            setPersona(prevState => ({
                ...prevState,
                [nameParts[0]]: value
            }));
        }
    };

    const handleModelChange = (value, name, data) => {
        const nameParts = name.split('.');

        //setHasSystemPrompt(data.hasSystemPrompt);

        if (nameParts.length === 3) {
            setPersona(prevState => handleNestedObjectChange(prevState, nameParts, value));
        }
        else {
            setPersona(prevState => ({
                ...prevState,
                [nameParts[0]]: value
            }));
        }
    };

    const handleToggle = (e) => {
        const { name, checked } = e.target;

        const nameParts = name.split('.');

        if (nameParts.length === 3) {
            setPersona(prevState => handleNestedObjectChange(prevState, nameParts, checked));
        }
        else {
            //update the persona object
            setPersona(prevState => ({
                ...prevState,
                [nameParts[0]]: checked
            }));
        }
    };

    const handleAdminGroupsMultiSelectChange = (value, option, options) => {
        setPersona(prevState => ({
            ...prevState,
            AdminGroups: value
        }));
    };

    const handleAccessGroupsMultiSelectChange = (value, option, options) => {
        setPersona(prevState => ({
            ...prevState,
            ApprovedGroups: value
        }));
    };

    function exportToJsonFile() {

        //convert the persona object to a string indented with 4 spaces
        let dataStr = JSON.stringify(persona, null, 4);
        let dataUri = 'data:application/json;charset=utf-8,' + encodeURIComponent(dataStr);

        let exportFileDefaultName = `${persona.Name}.json`;

        let linkElement = document.createElement('a');
        linkElement.setAttribute('href', dataUri);
        linkElement.setAttribute('download', exportFileDefaultName);
        linkElement.click();
    }


    const props = {
        name: 'file',
        action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
        headers: {
            authorization: 'authorization-text',
        },

        beforeUpload(file) {
            return new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsText(file);
                reader.onload = () => {
                    var loadedPersona = JSON.parse(reader.result);
                    loadedPersona.Id = null;
                    setPersona(loadedPersona);
                };
            });
        },
    }


    return (
        <div>
            {(visible) &&
                <Modal title="Persona Configuation"
                    open={visible}
                    onOk={handleSave}
                    onCancel={handleCancel}
                    okText="Save"
                    cancelText="Cancel" className="persona-configuration">

                    <Spin spinning={busyMessage !== null} tip={busyMessage} >
                        {(persona !== null) &&
                            <Form >
                                {mode === 'add' ? (
                                    <label>
                                        Name:
                                        <Input name="Name" value={persona.Name} onChange={handleChange} />
                                    </label>
                                ) : (
                                    <h3>{persona.Name}</h3>
                                )}
                                <label>
                                    Description:
                                    <Input name="Description" value={persona.Description} onChange={handleChange} />

                                </label>
                                <label>
                                    Groups With Admin Access (no selection means all groups have access):
                                    <ADGroupsDropdown name="AdminGroups" onChange={handleAdminGroupsMultiSelectChange} options={groupOptions} value={persona.AdminGroups} />
                                </label>
                                <label>
                                    Groups With Access (no selection means all groups have access):
                                    <ADGroupsDropdown name="ApprovedGroups" onChange={handleAccessGroupsMultiSelectChange} options={groupOptions} value={persona.ApprovedGroups} />
                                </label>
                                <label>
                                    Image Folder Name:
                                    <Input name="ImageFolderName" value={persona.ImageFolderName} onChange={handleChange} />
                                </label>
                                <label>
                                    Welcome Message:
                                    <Input name="WelcomeMessage" value={persona.WelcomeMessage} onChange={handleChange} />
                                </label>
                                <br />
                                <br />
                                {(visible && persona.Agents !== null) &&
                                    <Space direction="vertical" className="personas-list">
                                        {persona.Agents && Object.entries(persona.Agents).sort((a, b) => parseInt(a[1].DisplayOrder) > parseInt(b[1].DisplayOrder) ? 1 : -1).map(([agentName, agent], i) => (
                                            <div>
                                                <Card direction="vertical">
                                                    <Meta
                                                        avatar={<Avatar src={agent.IconUrl} />}
                                                        title={agentName}
                                                        description={agent.Description}
                                                        className="agent-card"
                                                    />

                                                    {
                                                        agent && agent.DesigntimeControlConfig && Object.entries(agent.DesigntimeControlConfig).map(([name, designTimeControlConfig], i) => (
                                                            <div>
                                                                {(() => {
                                                                    if (!designTimeControlConfig.Invisible) {
                                                                        switch (designTimeControlConfig.Type) {
                                                                            case "MultilineEdit":
                                                                                if (inferenceOptions !== null) {
                                                                                    var inferenceOption = inferenceOptions.filter((item) => item.value === agent.Inference)[0];
                                                                                    var hasSystemPrompt = false;

                                                                                    if (inferenceOption !== undefined && inferenceOption !== null && inferenceOption.data !== null && inferenceOption.data !== undefined) {
                                                                                        hasSystemPrompt = inferenceOption.data.hasSystemPrompt;
                                                                                    }
                                                                                    return (<><label> {designTimeControlConfig.Text} <TextArea ref={(ref) => inputRefs.current[name] = ref} name={`Agents.${agentName}.${designTimeControlConfig.Name}`} value={agent.Prompt} onChange={handleChange} rows={4} disabled={!hasSystemPrompt} /> </label> <br /><br /></>);
                                                                                }
                                                                                break;
                                                                            default:
                                                                                break;
                                                                        }
                                                                    }
                                                                })()}
                                                            </div>
                                                        ))
                                                    }

                                                    <Space direction="horizontal">
                                                        {
                                                            agent && agent.DesigntimeControlConfig && Object.entries(agent.DesigntimeControlConfig).map(([name, designTimeControlConfig], i) => (
                                                                <div>
                                                                    {(() => {
                                                                        if (!designTimeControlConfig.Invisible) {
                                                                            switch (designTimeControlConfig.Type) {
                                                                                case "Checkbox":
                                                                                    return (<><Checkbox name={`Agents.${agentName}.${designTimeControlConfig.Name}`} checked={agent[designTimeControlConfig.Name]} onChange={handleToggle} >{designTimeControlConfig.Text}</Checkbox></>);
                                                                                case "Number":
                                                                                    return (<><label> {designTimeControlConfig.Text} <InputNumber name={`Agents.${agentName}.${designTimeControlConfig.Name}`} value={agent[designTimeControlConfig.Name]} min={0} max={1000} onChange={(value, event) => handleNumberChange(value, `Agents.${agentName}.${designTimeControlConfig.Name}`)} /> </label></>);
                                                                                case "InferenceModelDropList":
                                                                                    return (<><label> {designTimeControlConfig.Text} &nbsp;
                                                                                        <Select name={`Agents.${agentName}.Inference`} options={inferenceOptions}
                                                                                            value={agent.Inference} style={{ width: 200 }} onChange={(value, event) => handleModelChange(value, `Agents.${agentName}.Inference`, event.data)} /></label></>);
                                                                                case "SearchMode":
                                                                                    return (<><label> {designTimeControlConfig.Text} &nbsp;
                                                                                        <Select name={`Agents.${agentName}.SearchMode`}
                                                                                            value={agent.SearchMode} style={{ width: 200 }} onChange={(value, event) => handleSelectChange(value, `Agents.${agentName}.SearchMode`)} defaultValue="Any">
                                                                                            <Option value="All" label="All"></Option>
                                                                                            <Option value="Any" label="Any"></Option>
                                                                                        </Select> 
                                                                                    </label></>);
                                                                                case "SearchProvider":
                                                                                    return (<><label> {designTimeControlConfig.Text} &nbsp;
                                                                                        <Select name={`Agents.${agentName}.SearchProvider`}
                                                                                            value={agent.SearchProvider} style={{ width: 200 }} onChange={(value, event) => handleSelectChange(value, `Agents.${agentName}.SearchProvider`)}>
                                                                                            <Option value="Cognitive" label="Cognitive Search" title="Cognitive Search" text="Cognitive Search"></Option>
                                                                                            <Option value="Elastic" label="Elastic Search" title="Elastic Search" text="Elastic Search"></Option>
                                                                                        </Select>
                                                                                    </label></>);
                                                                                case "SinglelineEdit":
                                                                                    return (<><br /><label> {designTimeControlConfig.Text} <Input name={`Agents.${agentName}.${designTimeControlConfig.Name}`} value={agent[designTimeControlConfig.Name]} onChange={handleChange} /> </label> <br /></>);

                                                                                default:
                                                                                    break;
                                                                            }
                                                                        }
                                                                    })()}
                                                                </div>
                                                            ))
                                                        }
                                                    </Space>
                                                    <br />
                                                    <br />
                                                    <div>
                                                        <Space direction="vertical" >

                                                            <label>Action Tags: &nbsp;
                                                                <Space direction="horizontal">
                                                                    {
                                                                        agent && agent.ActionTags &&
                                                                        Object.entries(agent.ActionTags).map(([tagIndex, tagName], i) => (
                                                                            <Tag color="blue">{tagName}</Tag>
                                                                        ))}
                                                                </Space>
                                                            </label>


                                                            <label>Default Next Agent: {agent.DefaultNextAgent ? (< Tag color="green"> {agent.DefaultNextAgent.Agent}</Tag>) : (<></>)}</label>
                                                        </Space>
                                                    </div>
                                                </Card>
                                                <br />
                                            </div>
                                        ))}



                                        <label>
                                            Show Chat Cost:&nbsp;
                                            <Checkbox name="ShowChatCost" checked={persona.ShowChatCost} onChange={handleToggle} />
                                        </label>
                                        <label>
                                            Show Debug Info:&nbsp;
                                            <Checkbox name="ShowDebugInfo" checked={persona.ShowDebugInfo} onChange={handleToggle} />
                                        </label>

                                        <Space><Button onClick={exportToJsonFile}>Export As JSON</Button>
                                            <Upload {...props}>
                                                <Button icon={<UploadOutlined />}>Upload Json Persona</Button>
                                            </Upload>
                                        </Space>
                                        <br />
                                    </Space>
                                }
                            </Form>
                        }
                    </Spin>
                </Modal>
            }
        </div >
    )
}

export default PersonaConfigurator;

