import React, { useState, useEffect } from 'react';
import { Drawer, List, Tooltip } from 'antd';

const TaskListPanel = ({ connection }) => {
    const [taskList, setTaskList] = useState(null);

    useEffect(() => {
        if (connection) {
            connection.on('UpdateTaskList', (chatId, taskList) => {
                setTaskList(taskList);
            });
        }

        return () => {
            if (connection) {
                connection.off('UpdateTaskList');
            }
        };
    }, [connection]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat(navigator.language, {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        }).format(date);
    };  

    return (
        <Drawer
            title={taskList?.Description || "Task List"}
                placement="right"
                onClose={() => setTaskList(null)}
                visible={taskList !== null && taskList.Tasks !== null && (taskList.Tasks.length > 0 || taskList.Description !== "")}
                autoFocus={false}
            >
            
        
           
            <List
                dataSource={taskList?.Tasks}
                renderItem={task => (
                    <Tooltip
                        title={
                            <div>
                                <p><strong>Due Date:</strong> {formatDate(task.TaskDueDate)}</p>
                                <p><strong>Priority:</strong> {task.TaskPriority}</p>
                                <p><strong>Status:</strong> {task.TaskStatus}</p>
                                <p><strong>Location:</strong> {task.TaskLocation}</p>
                            </div>
                        }
                    >  
                        <List.Item key={task.TaskNumber}>
                            {task.TaskNumber} - {task.TaskDescription}
                        </List.Item>
                    </Tooltip>
                )}
            />
            {
                taskList!==null && taskList?.Outcome !== "" && (
                    <p>
                        <strong>Outcome:</strong> {taskList?.Outcome}
                    </p>
                )
            }
        </Drawer>
    );
};

export default TaskListPanel;
