import React, { useState, useRef, useCallback } from 'react';
import { Button } from 'antd';
import { SoundOutlined, StopOutlined } from '@ant-design/icons'; 
import { useEffect } from 'react';

const VoiceChatButton = ({ connection, chatId, tokenManager }) => {
    const [voiceChatActive, setVoiceChatActive] = useState(false);    
    const [audioContext, setAudioContext] = useState(null);   
    const [processor, setProcessor] = useState(null); 
    const [stream, setStream] = useState(null); 
    const [workletNode, setWorkletNode] = useState(null); 
    const playbackNodeRef = useRef(null);

    const toggleVoiceChat = () => {
        if (voiceChatActive) {
            stopRecording();
        } else {
            startRecording();
        }
        setVoiceChatActive(!voiceChatActive);
    };    
    
    
    const desiredSampleRate = 24000; // 24kHz  


    // Function to stop recording  
    const doStopRecording = useCallback(() => {
        setVoiceChatActive(false);

        if (stream) {
            // Stop all tracks of the stream  
            stream.getTracks().forEach(track => track.stop());
            setStream(null);
        }

        if (workletNode) {
            // Disconnect the processor node  
            workletNode.disconnect();
            setWorkletNode(null);
        }

        if (processor) {
            // Disconnect the processor node  
            processor.disconnect();
            setProcessor(null);
        }

        if (audioContext) {
            // Close the AudioContext  
            audioContext.close();
            setAudioContext(null);
        }       

    }, [stream, workletNode, processor, audioContext]);

    // Function to stop recording  
    const stopRecording = useCallback(() => {
        doStopRecording();

        if (connection) {
            connection.invoke('ServerShouldStopRealtimeChat');
        }

        console.log('Audio stream stopped.');
    }, [connection, doStopRecording]);  

    // Convert Float32Array to 16-bit PCM  
    function floatTo16BitPCM(buffer) {
        const output = new Int16Array(buffer.length);
        for (let i = 0; i < buffer.length; i++) {
            let s = Math.max(-1, Math.min(1, buffer[i]));
            output[i] = s < 0 ? s * 0x8000 : s * 0x7FFF;
        }
        return output;
    } 

    // Resample the buffer to the desired sample rate using linear interpolation  
    function resampleBuffer(originalRate, targetRate, buffer) {
        if (originalRate === targetRate) {
            return buffer;
        }
        const sampleRatio = originalRate / targetRate;
        const newLength = Math.round(buffer.length / sampleRatio);
        const result = new Float32Array(newLength);
        let offsetResult = 0;
        let offsetBuffer = 0;
        while (offsetResult < result.length) {
            const nextSampleIndex = Math.floor(offsetBuffer);
            const frac = offsetBuffer - nextSampleIndex;
            if (nextSampleIndex + 1 < buffer.length) {
                result[offsetResult] = buffer[nextSampleIndex] * (1 - frac) + buffer[nextSampleIndex + 1] * frac;
            } else {
                result[offsetResult] = buffer[nextSampleIndex];
            }
            offsetResult++;
            offsetBuffer += sampleRatio;
        }
        return result;
    }  


    const handlePCM16Chunk = useCallback((chatId, data) => {
        const pcm16Data = new Int16Array(data.length/2);
        for (let i = 0; i < data.length / 2; i++) {
            pcm16Data[i] = data[(i * 2)] + (data[(i * 2) + 1] << 8);
        }

        play(pcm16Data);
    }, []);


    useEffect(() => {
        if (connection) {
            connection.on('SendAudioChunk', handlePCM16Chunk);
            connection.on('ClientShouldStopPlayback', clear);
            //connection.on('ShouldStopConversation', doStopRecording())
           }
    }, [connection, doStopRecording, handlePCM16Chunk]);


    function play(buffer) {
        if (playbackNodeRef.current) {
            playbackNodeRef.current.port.postMessage(buffer);
        }
    }

    function clear() {
        if (playbackNodeRef.current) {
            playbackNodeRef.current.port.postMessage(null);            
        }
    }

    // Function to start recording  
    async function startRecording() {
        try {
            var localAudioContext = new (window.AudioContext || window.webkitAudioContext)({
                sampleRate: desiredSampleRate,
            })

            // Load the AudioWorklet module  
            await localAudioContext.audioWorklet.addModule('processor.js');
            await localAudioContext.audioWorklet.addModule("playback-worklet.js");

            var localStream = await navigator.mediaDevices.getUserMedia({ audio: true });
            // Request microphone access  
            setStream(localStream);

            // Create a MediaStreamSource from the microphone input  
            const source = localAudioContext.createMediaStreamSource(localStream);

            // Create an AudioWorkletNode with the custom processor  
            
            playbackNodeRef.current = new AudioWorkletNode(localAudioContext, "playback-worklet", {
                outputChannelCount: [1] // Mono output  
            });
            playbackNodeRef.current.connect(localAudioContext.destination);
          

            var localWorkletNode = new AudioWorkletNode(localAudioContext, 'custom-processor');
            setWorkletNode(localWorkletNode);


            // Handle messages from the AudioWorkletProcessor  
            localWorkletNode.port.onmessage = (event) => {
                const audioData = event.data;                
                const resampledBuffer = resampleBuffer(localAudioContext.sampleRate, desiredSampleRate, audioData);
                const pcmData = floatTo16BitPCM(resampledBuffer);

                connection.invoke("RecieveAudioChunk", chatId, pcmData).catch(err =>
                    console.error(err)
                );
            };

            // Connect the nodes  
            source.connect(localWorkletNode); 
            localWorkletNode.connect(localAudioContext.destination);


            // Initialize AudioContext with a 24kHz sample rate  
            setAudioContext(localAudioContext);

            console.log('Recording started.');
        } catch (err) {
            console.error('Error accessing microphone or initializing AudioWorklet:', err);
        }
    }


    return (
        <div>
            <Button
                onClick={toggleVoiceChat}
                icon={voiceChatActive ? <StopOutlined /> : <SoundOutlined />}
                type={voiceChatActive ? "primary" : "default"}
            >
                {voiceChatActive ? "Stop Voice Chat" : "Start Voice Chat"}
            </Button>
            
        </div>
    );
};

export default VoiceChatButton;  
